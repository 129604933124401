* {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  margin: 20px;
  padding: 0;
  background-color: #fffcf1;
}

.note-selector:hover {
  opacity: 0.8 !important;
  transition: opacity 100ms ease-in;
}

.save-button {
  text-decoration: none;
  font-weight: bold;
  color: #8a2be2;
}

.save-button::before {
  content: "💾";
  margin-right: .5rem;
}

.save-button:hover {
  color: #6a00cc;
}

.save-button:focus {
  color: #6a00cc;
}

fieldset {
  margin-bottom: 1rem;
  background-color: #f0ece0;
}

fieldset button {
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}

.source-link {
  font-size: .8rem;
  display: inline-flex;
  align-items: center;
  margin-top: 1rem;
  text-decoration: none;
  color: #8a2be2;
}

.source-link svg {
  margin-right: .3rem;
  margin-bottom: 2px;
}


.source-link:hover {
  color: #6a00cc;
}

.source-link:focus {
  color: #6a00cc;
}